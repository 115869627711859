/* eslint-disable jsx-a11y/media-has-caption */

import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout/"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import Markdown from "../components/Markdown"

import recordings from "../cms/recordings"

export default () => (
  <Layout>
    <Head title="Recordings" />

    <PageHeading text="Recordings" />

    {recordings.videos.map(video => (
      <div
        key={video.video_file}
        css={css`
          margin-bottom: 6rem;
        `}
      >
        <video width="100%" height="auto  " src={`/${video.video_file}`} controls />

        <div
          css={css`
            margin-top: 1rem;
          `}
        >
          <Markdown
            css={css`
              font-size: 20px;
              font-weight: 700;
              margin: 0;
              color: var(--text-black);
            `}
            raw={video.title}
          />

          <Markdown
            css={css`
              color: var(--text-gray);
              margin-top: .3rem;
              margin-bottom: 0;
              font-size: 1rem;

              br {
                margin-bottom: 0;
              }
            `}
            raw={video.description}
          />
        </div>
        
      </div>
    ))}
  </Layout>
)